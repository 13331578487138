import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentPathNew } from '../../app/redux/slices/CurrentDirectoryNewSlice'
import { setCurrentPath } from '../../app/redux/slices/CurrentDirectorySlice'
import {
	fetchFolderCurrent,
	setCurrentFolder,
} from '../../app/redux/slices/currentFolderInfo'
import { setCurrentImagePath } from '../../app/redux/slices/CurrentImageSlice'
import { setDropDownData } from '../../app/redux/slices/DropDownSlice'
import { fetchDirectories } from '../../app/redux/slices/getAllDirectories'
import { setIsVoid } from '../../app/redux/slices/isVoid/isVoidSlice'
import { performSequentialActions } from '../../app/redux/slices/performSequentialActionSlice'
import { setPrevDataLeft } from '../../app/redux/slices/prevDataLeft/prevDataLeftSlice'
import { setCurrentPrevRightData } from '../../app/redux/slices/PrevRightDataSlice'
import { setSaverFolderData } from '../../app/redux/slices/SaveFolderDataSlice'
import { setSelectCurrentFirst } from '../../app/redux/slices/stateSelect/FirstSelectedPathSlice'
import {
	currentSelectState,
	setSelectCurrent,
} from '../../app/redux/slices/stateSelect/stateSelectSlice'
import { AppDispatch } from '../../app/redux/store'
import { BuildPath } from '../../features/BuildPath'
import getFileExtension from '../../features/FindExten'
import { FindToDeletePath } from '../../features/FindToDeletePath'
import splitText from '../../features/splitText'
import { arrIcons } from '../../shared/IconsAndExtensio'
import { FolderFace, FolderFaceNew, moveProps } from '../../shared/types/types'
import ButtonOption from '../../widgets/ButtonOption/ButtonOption'
import { ContextMenuV2 } from '../ContextMenuV2/ContextMenuV2'
import style from './BottomContextV2.module.scss'
const BottomContextV2: React.FC<FolderFaceNew> = ({
	name,
	type,
	path,
	isHaveContent,
	contents,
	index,
	size,
}) => {
	const dispatch = useDispatch<AppDispatch>()
	const dispatchThunk = useDispatch<ThunkDispatch<{}, {}, AnyAction>>()
	const { currentFolderInfo, loadingInfo, errorInfo } = useSelector(
		(state: any) => state.currentFolder
	)
	const [contextMenuPath, setContextMenuPath] = useState<string | null>(null)
	const { currentInfoAboutMove, loadingInfoMove, errorInfoMove } = useSelector(
		(state: any) => state.fetchMoveSlice
	)
	const PathOne = useSelector((state: any) => state.CurrentDirectorySlice)
	const [isContextMenu, setIsContextMenu] = useState<any>(false)
	const [idContextMenu, setIdContextMenu] = useState<any>(null)
	const [isExpanded, setIsExpanded] = useState(false)
	const [draggedItem, setDraggedItem] = useState<any>(null)
	const refBottomContext = useRef<any>(null)
	const { inputImage } = useSelector((state: any) => state.CurrentInputSlice)

	const PatnCurrent = useSelector((state: any) => state.CurrentDirectorySlice)
	const currentSelectStateLocal = useSelector(currentSelectState)
	const [click, setClick] = useState<number>(0)
	const [select, setSelect] = useState(false)
	const currentLeftPathLocal = useSelector(
		(state: any) => state.CurrentDirectoryNewSlice
	)
	function removeLastSegment(path: string): string {
		const lastSlashIndex = path.lastIndexOf('/')

		// Если слэш не найден, возвращаем исходную строку
		if (lastSlashIndex === -1) {
			return path
		}

		return path.slice(0, lastSlashIndex)
	}

	const handleFolderClick = () => {
		dispatch(setCurrentImagePath(path))
		//}
		if (contents && contents.length > 0 && !loadingInfo && errorInfo === null) {
			dispatch(setSelectCurrentFirst(path))
			let some: any = BuildPath(name, currentFolderInfo)
			//dispatch(setSelectCurrentFirst(path))
			dispatch(setPrevDataLeft(currentFolderInfo))
			dispatch(setCurrentFolder(contents))
			dispatch(fetchDirectories())
			dispatch(setDropDownData(contents))
			dispatch(setCurrentPath(path))
			dispatch(setIsVoid(false))
			dispatch(setCurrentPathNew(removeLastSegment(path)))
		} else if (
			contents?.length === 0 &&
			!loadingInfo &&
			errorInfo === null &&
			type === 'directory'
		) {
			dispatch(setIsVoid(true))
			dispatch(setSelectCurrentFirst(path))
			let some: any = BuildPath(name, currentFolderInfo)
			dispatch(setCurrentPath(some))
			dispatch(fetchDirectories())
			dispatch(setCurrentFolder([]))
		}
		dispatch(
			setCurrentPrevRightData({
				name,
				type,
				size,
				contents,
				isLeft: false,
			})
		)
	}
	useEffect(() => {
		if (click > 0 && PathOne.path.join('/').length > 0) {
			dispatch(fetchFolderCurrent(PathOne.path.join('/')))
			dispatch(setCurrentPath(PathOne.path.join('/')))

			dispatch(setDropDownData(contents))
		}
	}, [click])

	const handleExpandToggle = () => {
		setIsExpanded(!isExpanded)
	}
	const [extenState, setExtenState] = useState('')
	const [pathSrc, setPathSrc] = useState('')
	useEffect(() => {
		let extenOne: null | string = null
		let typeOne: null | 'directory' = null
		const exten: string = getFileExtension(name)
		if (exten.trim().length === 0) {
			extenOne = null
			setExtenState('Все')
		} else if (exten.trim().length > 0) {
			extenOne = exten
			setExtenState(exten)
		}
		if (type === undefined) {
			typeOne = null
		} else if (type === 'directory') {
			typeOne = 'directory'
			setExtenState('Папка')
		}

		const result: string = arrIcons(extenOne, typeOne)
		setPathSrc(result)
	}, [])

	const handleContextMenu = (event: React.MouseEvent) => {
		event.preventDefault() // Предотвращает открытие контекстного меню

		const elementId = (event.currentTarget as HTMLElement).id

		// Если id отличается от текущего или меню закрыто, обновляем его состояние
		if (elementId !== idContextMenu) {
			setIdContextMenu(elementId)
			setIsContextMenu(true)
		} else {
			// Закрываем меню, если кликнули по тому же элементу
			setIsContextMenu(false)
			setIdContextMenu(null)
		}
	}

	const handlePath = () => {
		const result = BuildPath(name, currentFolderInfo)

		let arg: string | null = FindToDeletePath({ name, data: currentFolderInfo })
		setContextMenuPath(result)
	}

	const handleDragStart = (event: React.DragEvent, item: any) => {
		setDraggedItem(item)

		event.dataTransfer.setData('text/plain', JSON.stringify(item))
	}

	const handleDragOver = (event: React.DragEvent) => {
		event.preventDefault()
	}
	useEffect(() => {
		dispatch(fetchFolderCurrent(PathOne.path.join('/')))
	}, [])
	function trimLastSegment(str: string): string {
		const lastSlashIndex = str.lastIndexOf('/')
		// Если в строке нет '/' или она содержит только одно слово, возвращаем пустую строку
		return lastSlashIndex === -1 ? '' : str.substring(0, lastSlashIndex)
	}

	function trimLastTwoSegments(str: string): string {
		// Если в строке нет '/', значит это одно слово, возвращаем пустую строку
		if (!str.includes('/')) return ''

		const lastSlashIndex = str.lastIndexOf('/')
		const secondLastSlashIndex = str.lastIndexOf('/', lastSlashIndex - 1)

		return secondLastSlashIndex !== -1
			? str.substring(0, secondLastSlashIndex)
			: ''
	}

	const handleDrop = (event: React.DragEvent, destination: any) => {
		event.preventDefault()

		const droppedItem = JSON.parse(event.dataTransfer.getData('text/plain'))

		let newPathLocal = destination.path.substring(
			0,
			destination.path.lastIndexOf('/')
		)

		if (
			droppedItem.path.startsWith(newPathLocal) &&
			droppedItem.type === 'directory'
		) {
			return
		}
		if (newPathLocal === droppedItem.path) {
			return
		}
		if (destination.type === 'directory') {
			let newPath = destination.path + '/' + droppedItem.name
			let oldPath = droppedItem.path

			if (newPath === oldPath) {
				return
			}
			dispatchThunk(
				performSequentialActions({
					newPath: destination.path,
					oldPath: droppedItem.path,
					PathOne,
					leftPath: trimLastSegment(currentLeftPathLocal.path.join('/')),
				})
			)
			return
		}
		if (
			PathOne.path &&
			PathOne.path.length !== 0 &&
			PathOne.path[0].trim().length !== 0 &&
			destination.name === '...'
		) {
			const result = currentFolderInfo
				? BuildPath(droppedItem.name, currentFolderInfo)
				: null

			let newSome: string[] = []
			if (result !== null) {
				newSome = result.split('/')
			}

			newSome.splice(newSome.length - 2, 2)
			let newResult = newSome.join('/')

			dispatchThunk(
				performSequentialActions({
					newPath: newResult,
					oldPath: droppedItem.path,
					PathOne,
					leftPath: trimLastSegment(currentLeftPathLocal.path.join('/')),
				})
			)

			return
		}

		if (destination.type !== 'directory') {
			dispatchThunk(
				performSequentialActions({
					newPath: PathOne.path.join('/'),
					oldPath: droppedItem.path,
					PathOne,
					leftPath: trimLastSegment(currentLeftPathLocal.path.join('/')),
				})
			)

			return
		}

		const oldPath: any = currentFolderInfo
			? BuildPath(droppedItem.name, currentFolderInfo)
			: null

		let dropInFolder: string = destination.name
		let newPath: any = currentFolderInfo
			? BuildPath(dropInFolder, currentFolderInfo)
			: null

		const dataMove: moveProps = { oldPath: droppedItem.path, newPath }

		if (PathOne.path.length === 0) {
			dispatchThunk(
				performSequentialActions({
					newPath,
					oldPath: droppedItem.path,
					PathOne,
					leftPath: currentLeftPathLocal.path.join('/'),
				})
			)

			return
		}
	}

	useEffect(() => {
		if (isContextMenu) {
			handlePath()
		}
	}, [isContextMenu])
	const handleCoutContext = () => {
		setIsContextMenu(false)
		setSelect(!select)
		dispatch(setSelectCurrent(name))
		dispatch(setSelectCurrentFirst(path))
		dispatch(setCurrentImagePath(path))
		dispatch(
			setSaverFolderData({
				name,
				type,
				path,
				isHaveContent,
				contents,
				index,
				size,
			})
		)
		dispatch(
			setCurrentPrevRightData({
				name,
				type,
				size,
				isLeft: false,
			})
		)
	}
	function formatBytes(bytes: number): string {
		if (bytes < 1024) return `${bytes} Б`
		const units = ['Кб', 'Мб', 'Гб', 'Тб']
		let unitIndex = -1
		let size = bytes

		do {
			size /= 1024
			unitIndex++
		} while (size >= 1024 && unitIndex < units.length - 1)

		return `${size.toFixed(2)} ${units[unitIndex]}`
	}
	const dataOpen: FolderFace = {
		name,
		type,
		path,
		isHaveContent,
		contents,
		size,
	}
	const { isList } = useSelector((state: any) => state.IsListSlice)
	// Закрытие контекстного меню, когда происходит клик вне элемента
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				refBottomContext.current &&
				!refBottomContext.current.contains(event.target as Node)
			) {
				setIsContextMenu(false)
				setIdContextMenu(null)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [refBottomContext])

	return (
		<div className={isList ? style.wrapperBlock : style.wrapperList}>
			<div
				id={name}
				onClick={handleCoutContext}
				onDoubleClick={() => {
					setSelect(!select)

					dispatch(setSelectCurrent(name))
				}}
				ref={refBottomContext}
				onContextMenu={handleContextMenu}
				className={
					currentSelectStateLocal === name
						? style.wrapperFolderSelect
						: style.wrapperFolder
				}
				draggable
				onDragStart={e => {
					handleDragStart(e, { name, type, path, isHaveContent, contents })
				}}
				onDragOver={handleDragOver}
				onDrop={e =>
					handleDrop(e, { name, type, path, isHaveContent, contents })
				}
			>
				<div
					id={path}
					className={style.folderHeader}
					onDoubleClick={handleFolderClick}
				>
					<img
						className={style.iconFiles}
						id={path}
						src={pathSrc}
						alt='folder'
					/>
					<span id={path} className={style.elementName}>
						{splitText(name, 15)}
					</span>
					<span className={style.elementSize}>{formatBytes(size)}</span>
					<span className={style.elementEditTime}>{extenState}</span>
					<div className={style.elementNone}>
						<ButtonOption folder={dataOpen} arg={path} />
					</div>
				</div>
				{type === 'directory' &&
					isExpanded &&
					contents &&
					contents?.length > 0 && (
						<div id={path} className={style.contents}>
							{contents.map((element: any) => {
								return <BottomContextV2 {...element} />
							})}
						</div>
					)}
				{isContextMenu && (
					<ContextMenuV2 isContext={isContextMenu} path={path} />
				)}
			</div>
		</div>
	)
}

export default BottomContextV2
