import axios from 'axios'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { setCurStatusManyFile } from '../../../app/redux/slices/statusSaveMany/statusSaveManySlice'
import { FileStatus, FolderFace } from '../../../shared/types/types'

// Удаление дубликатов в пути
function removeDuplicatePathSegments(fullPath: string): string {
	const pathParts = fullPath.split('/')
	const seen = new Set<string>()
	const result: string[] = []
	for (const part of pathParts) {
		if (!seen.has(part)) {
			seen.add(part)
			result.push(part)
		}
	}
	return result.join('/')
}

// Запрос файла и скачивание как Blob
async function fetchFile(path: string): Promise<any> {
	const response = await axios.get(
		`${process.env.REACT_APP_SERVER}/media/generateLink?webPath=${path}`
	)
	return response.data
}

// Добавление файлов и папок в ZIP
export async function addFilesToZip(
	zip: JSZip,
	contents: FolderFace[],
	pathFull: string,
	statusMap: Map<string, FileStatus>,
	dispatch: any, // Добавлен dispatch
	relativePath: string = ''
) {
	for (const item of contents) {
		let fullPath = item.path.startsWith(pathFull)
			? item.path
			: `${pathFull}/${item.path}`

		fullPath = removeDuplicatePathSegments(fullPath)

		if (item.type === 'directory') {
			const zipPath = fullPath.replace(new RegExp(`^${pathFull}/?`), '')
			zip.folder(zipPath)
			if (item.contents) {
				await addFilesToZip(
					zip,
					item.contents,
					pathFull,
					statusMap,
					dispatch,
					fullPath
				)
			}
		} else {
			statusMap.set(item.name, 'pending')
			try {
				const fileBlob = await fetchFile(fullPath)
				const response = await axios.get(fileBlob.link, {
					responseType: 'blob',
				})
				const zipPath = fullPath.replace(new RegExp(`^${pathFull}/?`), '')
				zip.file(zipPath, response.data)
				statusMap.set(item.name, 'fulfilled')
				dispatch(setCurStatusManyFile('pending')) // Устанавливаем промежуточный статус
			} catch (error) {
				statusMap.set(item.name, 'rejected')
				dispatch(setCurStatusManyFile('rejected'))
				console.error(`Ошибка при загрузке файла ${item.name}:`, error)
			}
		}
	}
}

// Основная функция для скачивания ZIP
export async function downloadZip(
	folder: FolderFace,
	pathFull: string,
	pathSelected: string,
	dispatch: any
) {
	const zip = new JSZip()
	const statusMap = new Map<string, FileStatus>()

	// Сборка ZIP с файлами
	await addFilesToZip(zip, folder.contents || [], pathFull, statusMap, dispatch)

	// Проверка статусов: проход по statusMap
	const statuses = Array.from(statusMap.entries())
	const allFulfilled = statuses.every(([_, status]) => status === 'fulfilled')

	// Если есть хотя бы один "неуспешный" статус, ставим "rejected"
	if (!allFulfilled) {
		dispatch(setCurStatusManyFile('rejected'))
		
		return
	}

	// Генерация ZIP и скачивание, если все файлы успешно добавлены
	try {
		const zipBlob = await zip.generateAsync({ type: 'blob' })
		saveAs(zipBlob, `${folder.name}.zip`) // Скачивание ZIP
	} catch (error) {
		// Обновление статуса в случае ошибки
		dispatch(setCurStatusManyFile('rejected'))
		
		return
	}

	// Установка статуса "fulfilled" только если все файлы успешны
	if(allFulfilled) {
		
		dispatch(setCurStatusManyFile('fulfilled'))

	}
}
