import { createSlice } from '@reduxjs/toolkit'
import { FileStatus } from '../../../../shared/types/types'
import { RootState } from '../../store'
//import { RootState } from '../store'

interface statusSaveFile {
	status: FileStatus
}
const initialState: statusSaveFile = {
	status: 'fulfilled',
}

export const SavingManySlice = createSlice({
	name: 'dataStateMany',
	initialState,
	reducers: {
		setCurStatusManyFile: (state, action) => {
			state.status = action.payload
		},
	},
})

export const statusDataManySaving = (state: RootState) =>
	state.SavingManySlice.status
export const { setCurStatusManyFile } = SavingManySlice.actions

export default SavingManySlice.reducer
