import axios from 'axios'
import { saveAs } from 'file-saver'
import { v4 as uuidv4 } from 'uuid'
import { setCurStatusOneFile } from '../../../app/redux/slices/statusSaveOne/statusSaveOneSlice'
import { AppDispatch } from '../../../app/redux/store'
import { FileStatus } from '../../../shared/types/types'

// Функция для обновления статуса файла через Redux
const setStatusOneFile = (dispatch: AppDispatch, status: FileStatus) => {
	dispatch(setCurStatusOneFile(status))
}

// Асинхронная функция загрузки файла
export const downloadFile = async (
	url: string,
	dispatch: AppDispatch
): Promise<Blob> => {
	// Инициализируем статус как "pending"
	let status: FileStatus = 'pending'
	setStatusOneFile(dispatch, 'pending')

	try {
		// Запрашиваем файл в виде Blob
		const response = await axios.get(url, {
			responseType: 'blob',
		})

		// Сохраняем файл с помощью file-saver
		saveAs(response.data, uuidv4())

		// Обновляем статус на "fulfilled"
		status = 'fulfilled'
		setStatusOneFile(dispatch, 'fulfilled')

		// Возвращаем Blob, если нужно использовать его далее
		return response.data
	} catch (error) {
		// Обновляем статус на "rejected" в случае ошибки
		status = 'rejected'
		setStatusOneFile(dispatch, 'rejected')
		console.error(`Статус загрузки файла с URL ${url}:`, status, error)

		throw new Error('Не удалось загрузить файл. Попробуйте снова.')
	}
}
